import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'hooks'


type ApiData = {
  label: string, subscribed: number, emailSubscribed: number,
  rights: number, value: number, smsSubscribed: 1 | 0,
}[]

const useChannelsList = (props?: { skip?: boolean }) => {
  const { skip } = props || {}

  const [ tryCount, setTryCount ] = useState(0)

  const queryOptions = useMemo(() => ({
    url: `/ajax.php?action=chatChannelSearch&try=${tryCount}`,
    skip,
    modifyResult: (data) => {
      return data?.filter(({ value, deleted }) => !deleted && value < 300) || []
    },
  }), [ skip, tryCount ])

  const { data, isFetching } = useQuery<ApiData>(queryOptions)

  useEffect(() => {
    if (data?.length === 0 && !tryCount) {
      const timeout = setTimeout(() => {
        setTryCount(1)
      }, 100)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [ data, tryCount ])

  return {
    channelsList: data || [],
    isChannelsListFetching: isFetching,
  }
}


export default useChannelsList
