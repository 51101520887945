import React, { ReactNode, useRef } from 'react'
import cx from 'classnames'

import { Tooltip } from 'components/feedback'
import { ButtonBase } from 'components/inputs'

import s from './CircleButton.scss'


export type CircleButtonProps = {
  className?: string
  contentNode: ReactNode
  bottomNode?: ReactNode
  tooltip?: string
  to?: string
  disabled?: boolean
  onClick?: () => void
}

const CircleButton: React.FC<CircleButtonProps> = (props) => {
  const { className, contentNode, bottomNode, tooltip, to, disabled, onClick } = props

  // const fileInputRef = useRef()

  const circleNode = (
    <div className={cx(s.circle, 'relative radius-100 w-full')}>
      <div className={cx(s.content, 'w-full h-full flex items-center justify-center')}>
        {contentNode}
      </div>
    </div>
  )

  return (
    <ButtonBase
      className={cx(className, s.container, 'relative overflow-hidden')}
      to={to}
      disabled={disabled}
      onClick={onClick}
    >
      {
        tooltip ? (
          <Tooltip message={tooltip} position="top-right">
            {circleNode}
          </Tooltip>
        ) : circleNode
      }
      {bottomNode}
    </ButtonBase>
  )
}


export default CircleButton
