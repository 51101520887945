import { useCallback, useMemo } from 'react'
import config from 'config'

import { useChannels } from 'models/notifications'

import useTKONotifications from './useTKONotifications'
import useEventsNotifications from './useEventsNotifications'
import useSensorsNotifications from './useSensorsNotifications'
import useImmortalsNotifications from './useImmortalsNotifications'


type UseNotificationsProps = {
  channelId?: number
  withRefetch?: boolean
}

const useNotifications = ({ channelId }: UseNotificationsProps = {}) => {
  const { channels, notifyTags } = useChannels()

  const skipSensors = !notifyTags?.includes('IOT')
  const skipTKO = !notifyTags?.includes('GARBAGE') || ![ 'Курчатов', 'Волгодонск' ].includes(config.cityName)
  const skipImmortals = !notifyTags?.includes('IMMORTALS')

  const { sensorsData, isSensorsFetching, fetchSensors } = useSensorsNotifications({ skip: skipSensors })
  const { tkoData, isTKOFetching, fetchTKO, submitReadTKO } = useTKONotifications({ skip: skipTKO })
  const { immortalsData, fetchImmortals } = useImmortalsNotifications({ skip: skipImmortals })
  const { eventsData, notificationLinks, isEventsDataFetching, submitReadEvents, fetchEventsData } = useEventsNotifications()

  const isFetching = isSensorsFetching || isTKOFetching || isEventsDataFetching

  const submitRead = useCallback(async () => {
    submitReadTKO(channelId)
    submitReadEvents(channelId)
  }, [ submitReadTKO, submitReadEvents, channelId ])

  const fetch = useCallback(() => (
    Promise.all([
      skipTKO ? Promise.resolve(null) : fetchTKO(true),
      skipSensors ? Promise.resolve(null) : fetchSensors(true),
      skipImmortals ? Promise.resolve(null) : fetchImmortals(true),
      fetchEventsData(true),
    ])
  ), [
    fetchTKO,
    fetchSensors,
    fetchEventsData,
    fetchImmortals,
    skipTKO,
    skipSensors,
    skipImmortals,
  ])

  const count = useMemo(() => {
    if (!isFetching) {
      return (eventsData?.count || 0) + (sensorsData?.count || 0) + (tkoData?.count || 0) + (immortalsData?.count || 0)
    }

    return 0
  }, [ eventsData, sensorsData, tkoData, immortalsData, isFetching ])

  const channelsList = useMemo<Notifications.Channel[]>(() => {
    const list: Notifications.Channel[] = []

    if (!skipSensors) {
      list.push({
        id: 'sensors',
        title: 'Счётчики',
      })
    }
    if (!skipTKO) {
      list.push({
        id: 'tko',
        title: config.cityName === 'Курчатов' ? 'Видеонаблюдение' : 'ТКО',
      })
    }
    if (!skipImmortals) {
      list.push({
        id: 'immortals',
        title: 'Бессмертный полк',
      })
    }

    return channels
      ? list.concat(channels).filter(({ mergedId }) => !mergedId)
      : list
  }, [ channels, skipTKO, skipSensors, skipImmortals ])

  const notifications = useMemo(() => {
    const result = []

    if (eventsData?.notifications) {
      result.push(...eventsData.notifications)
    }

    if (sensorsData?.notifications) {
      result.push(...sensorsData.notifications)
    }

    if (tkoData?.notifications) {
      result.push(...tkoData.notifications)
    }

    if (immortalsData?.notifications) {
      result.push(...immortalsData.notifications)
    }

    return result
  }, [ eventsData, sensorsData, tkoData, immortalsData ])

  return {
    channelsList,
    notifications,
    notificationLinks,
    count,
    submitRead,
    isNotificationsFetching: isFetching,
    fetch,
  }
}


export default useNotifications
